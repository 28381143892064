.page-portfolio .work {
  &.highlighted {
    width: 20rem;
  }

  header {
    h1 {
      width: 100%;
      font-size: 2rem;
      overflow-wrap: break-word;
    }
    button {
      top: 1rem;
      right: 1rem;
    }
  }

  &-content section {
    width: 100%;
    padding: 0 2rem 2rem;

    &.meta-images {
      padding: 2rem 0 0 0;
    }
  }

  .images {
    height: 15rem;
    &.draggable {
      padding: 0 2rem 2rem;

      img:first-child {
        padding-left: 0;
      }
      img:last-child {
        padding-right: 0;
      }
    }
  }

  .up {
    display: none;
  }

  .work-buttons {
    padding: 0 1rem 1rem;
  }
}
