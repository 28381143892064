.page-container {
  position: relative;
  width: 300%;
  height: auto;
  padding-bottom: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  transition: left .5s;

  &.home {
    left: 0;
  }
  &.portfolio {
    left: -100%;
  }
  &.contact {
    left: -200%;
  }
}

.page-background {
  position: absolute;
  height: $page-height;
  width: 100vw;
  background-image: url('../img/background.jpg');
  background-repeat: repeat-x;
  background-position: left center;
}

.page {
  height: $page-height;
  width: $page-width;
  box-shadow: $level-1;

  &-home {
    box-shadow: none;
  }

}
