.page-contact > article {
  flex-direction: column;
  background-size: cover;

  .text {
    padding-bottom: 2rem;

    p {
      width: auto;
    }
  }
}
