.page-home {
  flex-direction: column;

  .chapter {
    height: 4rem;
    width: 100%;
    border-bottom: none;
    border-left: $secondary-color-dark solid .5rem;
    background-size: cover;

    &:hover {
      width: 100%;
      height: 4rem;
    }

    &.active {
      height: $page-height;
      flex-grow: 2;
      width: 100%;
    }
    h3.header {
      position: initial;
      transform: none;
      width: 100%;
      line-height: 2;
    }

    .content {
      padding: 0;
      width: 100%;
      height: calc(100% - 4rem);
      background-position: top center;
      background-size: auto 65vh;

      .text,
      .images {
        display: none;
        max-width: 100%;

        h4 span {
          display: inline-block;
          width: 100%;
        }
      }
      .images.main {
        display: flex;
      }
      .text.main {
        display: block;
        overflow: auto;
        padding: 2rem;
      }
    }
  }
}
