nav {
  background-color: white;
  box-shadow: 0 0 5px rgba(51, 51, 51, 0.7);
  width: 100%;
  padding: .5rem;
  margin-bottom: 0;
  flex: 0 1 auto;
  justify-content: space-between;

  button {
    background: transparent;
    padding: .5rem 1rem;
    color: $secondary-color;
    font-size: .75rem;
    text-transform: uppercase;

    &.active,
    &:hover {
      background: transparent;
      color: $primary-color;
      box-shadow: none;
    }
  }

  section:last-child {
    padding: 0;
  }
}
