.portfolio {
  @include hide-scrollbar();
}

.page {
  height: 100%;
  width: calc(100% / 3);
  overflow-y: scroll;
  overflow-x: hidden;
  @include hide-scrollbar();

  &-container {
    flex: 2 0 auto;
    height: 0;
    padding: 0;

    &.portfolio {
      overflow-y: scroll;
    }
  }

  &-background {
    display: none;
  }
}
