header {
  padding-top: 4rem;
  width: $header-width;
  margin: auto;

  h1 {
    color: $secondary-color-dark;
    font-size: 3.5rem;
  }

  h2 {
    color: $secondary-color-light;
    font-size: 2rem;
    padding-left: 1rem;
  }
}
