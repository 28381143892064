header {
  width: 100%;
  padding: .5rem;
  background-color: white;
  flex: 0 1 auto;
  z-index: 2;

  h1 {
    font-size: 2rem;
  }

  h2 {
    display: none;
  }
}
