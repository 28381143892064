$primary-color-light: #ffc62e;
$primary-color: #ffba00;
$primary-color-dark: #e8a900;

$secondary-color-light: #999;
$secondary-color: #666;
$secondary-color-dark: #333;

$header-width: 55rem;

$level-2: 0px 0px 5px rgba(51, 51, 51, 0.7);
$level-1: 0px 0px 3px rgba(51, 51, 51, 0.4);
$level-0: none;
$level--1: 0px 0px 3px rgba(51, 51, 51, 0.4) inset;
$level--2: 0px 0px 5px rgba(51, 51, 51, 0.7) inset;

$page-height: 24.5rem;
$page-width: 75rem;