$section-padding: 0 15rem 2rem;
$carousel-nav-width: 6rem;

.work {
  &.active {
    cursor: initial;
  }
  &.active,
  &:hover {
    header .visual-logo {
      box-shadow: $level--2;
    }
  }
  header {
    padding: 0;
    width: 100%;

    .visual {
      width: 100%;
      height: #{ $page-height / 2 };
      background: center;
      background-size: cover;
      position: relative;

      &-logo {
        position: absolute;
        width: 5rem;
        top: 1rem;
        right: 1rem;
        padding: 1rem;
        background-color: white;
        border-radius: 2rem;
        box-shadow: none;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    h1 {
      position: relative;
      width: #{ $page-width - $work-spacing };
      font-size: 3rem;
      padding: 2rem;
      text-align: center;
    }

    button {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }

  &-section {
    width: #{$page-width - $work-spacing}; // fixed width to prevent wrapping on opening/closing item
    padding: $section-padding;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    p,
    table,
    h2,
    blockquote,
    cite {
      width: 100%;
      color: $secondary-color-dark;
    }

    &.header {
      padding-bottom: 0;
    }

    &.citation {
      padding: 1rem 15rem 2rem;
      align-items: flex-end;

      blockquote {
        font-size: 1.1rem;
        line-height: 1.75rem;
        font-style: italic;
        padding-left: 2rem;
        border-left: .5rem solid $secondary-color;
      }
      cite {
        padding-top: 1rem;
        width: 50%;
        text-align: right;
        font-size: .8rem;
        line-height: 1.2rem;
        font-style: normal;
      }
    }

    &.images {
      height: 25rem;
      padding: 0 0 2rem;
      overflow-x: scroll;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: normal;
      user-select: none;
      transition: transform calc(#{$open-animation-time} * 2);
      @include hide-scrollbar();

      img {
        height: 100%;
        margin: auto;
      }

      &.draggable {
        padding: $section-padding;

        img {
          position: relative;
          padding: 0 .25rem;
          height: 95%;
          user-select: none;
          transition: height $open-animation-time;

          &.active {
            height: 100%;
          }
        }
      }
    }
  }

  &-meta {
    table {
      padding: 0;
      text-align: left;

      td {
        font-size: .9rem;
        padding: .2rem;
        border-bottom: 1px dotted $secondary-color-light;
        text-transform: capitalize;

        &:nth-child(2) {
          text-align: right;
        }
      }
    }

    .meta-images {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2rem 2rem 0;

      img {
        width: 3rem;
      }
    }
  }

  &-buttons {
    text-align: right;
    width: #{$page-width - $work-spacing}; // fixed width to prevent wrapping on opening/closing item
    padding: 0 2rem 2rem;
  }

  button {
    background-color: white;
    border: 1px solid $secondary-color-light;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: box-shadow .2s;
    margin-left: 1rem;
    line-height: 1rem;

    &.close::before {
      content: "🗙"
    }

    &.up::before {
      content: "▲";
    }

    &:hover {
      box-shadow: $level-1;
    }
  }
}
