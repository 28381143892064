nav {
  position: relative;
  z-index: 1;
  padding-top: 1rem;
  width: $header-width;
  margin: auto auto 5rem;
  display: flex;
  align-items: flex-end;

  section {
    padding-right: 2rem;

    &:last-child {
      button {
        font-size: .75rem;
        padding: .5rem 1rem;
        &:first-child {
          border-bottom-left-radius: .5rem;
          border-top-left-radius: .5rem;
        }

        &:last-child {
          border-bottom-right-radius: .5rem;
          border-top-right-radius: .5rem;
        }
      }
    }
  }

  button {
    position: relative;
    padding: 1rem 2rem;
    border: none;
    color: $primary-color-dark;
    background-color: $secondary-color;
    transition: box-shadow .2s;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;

    &:hover {
      background-color: $secondary-color-light;
    }

    &.active {
      background-color: $secondary-color-light;
      box-shadow: $level--2;
    }

    &:first-child {
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
    }

    &:last-child {
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
}
