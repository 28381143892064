$work-spacing: 1rem;

.page-portfolio {
  height: auto;
  background-color: $primary-color;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: calc(#{ $work-spacing } / 2);
  position: relative;

  .work {
    width: 15rem;
    flex: 1 0 15rem;
    margin: #{ $work-spacing / 2 };
    max-height: #{ ($page-height - $work-spacing * 3) / 2 };
    cursor: pointer;
    z-index: 0;

    &.highlighted {
      width: 40rem;
      flex-grow: 2;
    }

    &-content {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, .2);
        z-index: 1;
      }
    }

    &.active {
      z-index: 2;
    }

    &.active,
    &:hover {
      .work-content {
        box-shadow: $level-2;

        &::before {
          background: transparent;
          position: relative;
        }
      }
    }
  }
}

@import "./portfolio/work";
