/* CSS Document */
@import 'styles/variables';
@import 'styles/mixins';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Trebuchet, arial, sans-serif;
  color: #333;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Consolas, Monaco, monospace;
}

p {
  font-size: 1.2rem;
  line-height: 1.4;
}

a,
a:visited {
  color: $primary-color-dark;

  &:hover {
    color: $primary-color-light;
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem 0;

  a,
  span {
    padding: 0 1rem;

    &:first-child {
      border-right: 1px solid $secondary-color-dark;
    }
  }
}

@import 'styles/header';
@import 'styles/nav';
@import 'styles/page';
@import 'styles/transitions';
@import 'styles/pages/home';
@import 'styles/pages/portfolio';
@import 'styles/pages/contact';

@media only screen and (max-width: $page-width) {
  html {
    height: 100%;
  }
  body {
    background-color: transparent;
    overflow-y: hidden;
    height: 100%;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  footer {
    position: inherit;
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.7);
    font-size: .8rem;
  }

  @import 'styles/header-mobile';
  @import 'styles/nav-mobile';
  @import 'styles/page-mobile';
  @import 'styles/pages/home-mobile';
  @import 'styles/pages/portfolio-mobile';
  @import 'styles/pages/contact-mobile';
}
