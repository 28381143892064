.page-contact {
  color: white;

  article {
    padding: 2rem;
    display: flex;
    height: 100%;
  }

  td {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .text {
    flex: 1 0;

    p {
      width: 30rem;
    }
  }

  .info {
    flex: 0 0;

    td:first-child {
      padding-right: 1rem;
      text-transform: capitalize;

      &::after {
        content: ":";
      }
    }

    .socials {
      padding-top: 2rem;
      list-style-type: none;
      display: flex;
      justify-content: space-between;

      img {
        height: 32px;
        filter: brightness(0) invert(1);

        &:hover {
          filter: invert(88%) sepia(44%) saturate(4518%) hue-rotate(330deg) brightness(102%) contrast(103%);
        }
      }
    }
  }
}
